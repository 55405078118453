<template>
  <div class="tasks">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <TaskTable />
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import TaskTable from '@/components/TaskTable';

export default {
  name: 'Tasks',
  components: {
    Breadcrumbs,
    TaskTable,
  },
  data() {
    return {};
  },
  computed: {
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('tasks') }];
    },
  },

  methods: {},

  async mounted() {},
};
</script>

<style scoped lang="scss"></style>
